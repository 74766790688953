import _util from "util";
import _fs from "fs";
var exports = {};
const {
  promisify
} = _util;
const fs = _fs;

async function isType(fsStatType, statsMethodName, filePath) {
  if (typeof filePath !== "string") {
    throw new TypeError(`Expected a string, got ${typeof filePath}`);
  }

  try {
    const stats = await promisify(fs[fsStatType])(filePath);
    return stats[statsMethodName]();
  } catch (error) {
    if (error.code === "ENOENT") {
      return false;
    }

    throw error;
  }
}

function isTypeSync(fsStatType, statsMethodName, filePath) {
  if (typeof filePath !== "string") {
    throw new TypeError(`Expected a string, got ${typeof filePath}`);
  }

  try {
    return fs[fsStatType](filePath)[statsMethodName]();
  } catch (error) {
    if (error.code === "ENOENT") {
      return false;
    }

    throw error;
  }
}

exports.isFile = isType.bind(null, "stat", "isFile");
exports.isDirectory = isType.bind(null, "stat", "isDirectory");
exports.isSymlink = isType.bind(null, "lstat", "isSymbolicLink");
exports.isFileSync = isTypeSync.bind(null, "statSync", "isFile");
exports.isDirectorySync = isTypeSync.bind(null, "statSync", "isDirectory");
exports.isSymlinkSync = isTypeSync.bind(null, "lstatSync", "isSymbolicLink");
export default exports;
export const isFile = exports.isFile,
      isDirectory = exports.isDirectory,
      isSymlink = exports.isSymlink,
      isFileSync = exports.isFileSync,
      isDirectorySync = exports.isDirectorySync,
      isSymlinkSync = exports.isSymlinkSync;